<template scoped>
  <CRUD-CreateUpdate
    title="Solution Role"
    :toPage="toPage"
    :endpoint="endpoint"
    :parameter="parameter"
    apiPath="Backend/SolutionRole"
    :back="back"
    :fields="fields"
  >
  </CRUD-CreateUpdate>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  components: {
    "CRUD-CreateUpdate": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-CreateUpdate.vue"),
  },
  data: function () {
    return {
      fields: [
        {
          name: "name",
          label: "Name",
          type: "String",
        },
        {
          name: "solutionId",
          label: "Solution Id",
          apiPath: "Backend/Solution",
          valueName: "solutionName",
          type: "Suggestion",
        },
      ],
    };
  },
  methods: {
    back: function () {
      this.toPage("components/SolutionRole/Portal-SolutionRole.vue");
    },
  },
};
</script>
